import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "Dev | C@iA.parts ";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 3002,
    Michelin: 3003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://dev-cloudio.ia.parts/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://dev-cloudio.ia.parts/",
    diag: "https://run.ia.parts",
    erp: "https://dev-cloudio.ia.parts/",
    store: "https://dev-cloudio.ia.parts/",
    visio: "https://meetbox.ia.parts",
    com: "https://dev-cloudio.ia.parts/",
    help: "https://dev-cloudio.ia.parts/",
    tecrmi: "https://rmi.ia.parts",
};

export const iframePartSelector = {
    fr: "https://www.eurorepar.fr/promotions.html",
    en: "https://www.eurorepar.fr/promotions.html",
};

export const iframeCarSelector = {
    fr: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
    en: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
};

export const iframeContactSelector = {
    fr: "https://hub.katia.parts/index.php/apps/files/",
    en: "https://cloud.ia.parts/nextcloud/index.php/apps/files/",
};

export const DIAG_OTHER_BRANDS = [
    {
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
    },
    {
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
    },
    {
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
    },
    {
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
    },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://dev.ia.parts/workshop/CarSelector/?mode=tablette",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const USE_KEYCLOAK = true;

export const LEVAM_URL = "https://oem1.ia.parts/oem_dev.html";
